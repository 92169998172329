<template>
  <div class="loginwrap">
    <div class="leftimgbox">
      <img src="../../../assets/imgs/loginimg1.png" alt />
    </div>
    <div class="rightform">
      <div class="formbox">
        <el-row>
          <el-col class="api-center" :xs="24" :sm="23" :md="18" :lg="17">
            <h2 class="api-title">
              <img src="../../../assets/imgs/logo.png" alt />
            </h2>
            <el-form
              ref="loginFormRef"
              class="loginform"
              :model="loginForm"
              :rules="loginFormRules"
            >
              <el-form-item prop="user">
                <el-input placeholder="用户名/手机号/邮箱" v-model="loginForm.user">
                  <i slot="prefix" class="iconfont iconzhanghao"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="email">
                <el-input placeholder="邮箱" v-model="loginForm.email">
                  <i slot="prefix" class="iconfont iconyouxiang3"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="pass">
                <el-input
                  placeholder="请输入6到16个字符密码"
                  type="password"
                  v-model="loginForm.pass"
                  @keyup.enter.native="handellogoForm('loginFormRef')"
                >
                  <i slot="prefix" class="iconfont iconmima"></i>
                </el-input>
              </el-form-item>
              <el-form-item class="api-forget">
                <span class="fr">
                  已有账号，欢迎
                  <router-link :to="{ name:'login' }" tag="a">登录</router-link>
                </span>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  style="width:100%;background:#2F95E3;border:none;"
                  v-loading="btnLoading"
                  @click="handellogoForm('loginFormRef')"
                >注册</el-button>
              </el-form-item>
              <el-form-item class="api-forget" prop="agreement">
                <span class="fl">
                  <el-checkbox-group v-model="loginForm.agreement" class="check">
                    <el-checkbox name="agreement"></el-checkbox>
                  </el-checkbox-group>
                  <span class="textagree" @click="dialogVisible = true">我已阅读并接受《豆豆记注册协议》</span>
                </span>
              </el-form-item>
            </el-form>
            <el-dialog title="注册协议" :visible.sync="dialogVisible" width="50%" center>
              <div class="xieyibox">
                <p>
                  1、服务对象
                  同意本协议并注册成为为豆豆记的用户。
                </p>
                <p>
                  2、服务内容
                  为豆豆记用户提供在线图书、专栏, 创作、编辑、购买、存储等服务。
                </p>
                <p>
                  3、知识产权
                  用户在豆豆记平台上发布的在线图书、专栏等所有内容，著作权均归作者所有。用户可授权第三方以任何方式使用，不需要得到豆豆记的同意。
                  用户在豆豆记平台上发布的在线图书、专栏等所有内容，豆豆记有权将该内容用于豆豆记各类产品和服务上，以便提供更好服务和宣传。
                  用户在豆豆记平台上发布的在线图书、专栏等所有内容，应保证其为著作权人或取得合法授权。
                  如有侵权行为，可以向豆豆记官方进行举报，官方会在审查落实后进行相关处理，包括但不限于文档下架、删除以及追缴盗版收益等，并保留移交司法机关处理的权利。
                </p>
                <p>
                  3、用户的帐号，密码及管理
                  <i>用户应当遵守法律法规、规章、规范性文件及政策要求，谨慎合理使用和妥善保管豆豆记账号及密码</i>
                </p>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
              </span>
            </el-dialog>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import {  registerCheckRequest,registerRequest } from "@/api/user.js";
export default {
  data() {
    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("名字不能为空"));
      } else if (value.length < 2 || value.length > 16) {
        return callback(new Error("长度在 2 到 20 个字符"));
      } else {
        var isOkFn = this.checkName("username", value);
        isOkFn.then(function(data) {
          if (data == false) {
            return callback(new Error("该用户名已注册"));
          } else {
            callback();
          }
        });
      }
    };
    var validateEmail = (rule, value, callback) => {
        var isOkFn = this.checkName("email", value);
        isOkFn.then(function(data) {
          if (data == false) {
            return callback(new Error("该邮箱已注册"));
          } else {
            callback();
          }
        });
      
    };
    return {
      btnLoading: false,
      loginForm: {
        user: "",
        pass: "",
        email: '',
        agreement: []
      },
      dialogVisible: false,
      loginFormRules: {
        user: [{ validator: validateName, trigger: "blur" }],
        pass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 16, message: "长度在 6 到 16 个字符", trigger: "blur" }
        ],
        email:[
           { required: true, message: '请输入邮箱地址', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: "blur"},
             { validator: validateEmail, trigger: "blur" }
        ],
        agreement: [
          {
            required: true,
            message: "请阅读并同意勾选下方协议",
            trigger: "change"
          }
        ]
      }
    };
  },
  created() {},
  methods: {
    async registerFormSubmit() {
      const result = await registerRequest({
        username: this.loginForm.user,
        email:this.loginForm.email,
        password: this.$md5(this.loginForm.pass)
      });
      if (result.status === 200) {
         this.$message({
          showClose: true,
          message: result.message,
          type: "success"
        });
        this.$router.push({ name: "login" });
      } else {
        this.$message({
          showClose: true,
          message: result.message,
          type: "error"
        });
        this.btnLoading = false;
      }
      this.btnLoading = false;
    },
    handellogoForm(formName) {
      this.$refs[formName].validate(valid => {
        this.btnLoading = true;
        try {
          if (!valid) {
            this.btnLoading = false;
            return;
          }
          this.registerFormSubmit();
        } catch (err) {
          this.$message({
            showClose: true,
            message: "注册失败",
            type: "error"
          });
          this.btnLoading = false;
          console.log(err);
        }
      });
    },
    async checkName(user, val) {
      try {
        const oData = {};
        oData[user] = val;
        const result = await registerCheckRequest(oData);
        return result;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
<style lang="less" scoped>
/deep/html,
/deep/body,
/deep/#app {
  margin: 0;
  padding: 0;
  height: auto;
  min-height: 100%;
}
.loginwrap {
  height: 100%;
  min-height: 100%;
  background: url("../../../assets/imgs/loginbg.png");
  background-size: 100% 100%;
  display: flex;
  .leftimgbox {
    flex: 1;
    box-sizing: border-box;
    padding: 50px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .rightform {
    flex: 1;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    .formbox {
      width: 100%;
      padding: 20px;
    }
    /deep/.api-center {
      background: #fff;
      padding: 60px;
      -webkit-box-shadow: 0px 0px 10px #c7ddec;
      box-shadow: 0px 0px 17px #c7ddec;
      border-radius: 10px;
      .api-title {
        font-size: 34px;
        line-height: 50px;
        text-align: center;
        margin-bottom: 10px;
      }
      /deep/.el-input__prefix {
        left: 12px;
      }
      /deep/.el-input--prefix .el-input__inner {
        padding-left: 35px;
      }
      .api-forget {
        margin: -10px 0 15px 0;
        font-size: 13px;
        a {
          color: #2f95e3;
          text-decoration: none;
        }
      }
    }
    .check{
      float: left;
      margin-right: 10px;
    }
  }
}
</style>